import { useRouter } from 'next/router';
import { useMemo } from 'react';
import BlankScreen from '../structure/BlankScreen';
import UserContext from './context';
import { useUser } from './hooks';
import { ChildrenProps, UserData, UserHookReturn } from './types';

export const AuthContainer = ({ children }: ChildrenProps) => {
  const router = useRouter();
  const { user, mutateUser, logout, isLoading, isRedirected }: UserHookReturn =
    useUser({
      redirectTo: '/login',
    });

  const providerValue = useMemo(
    () => ({ user, mutateUser, logout, isLoading, isRedirected }),
    [user, mutateUser, isLoading]
  );

  if (isLoading || isRedirected) {
    return <BlankScreen />;
  }

  const userLang = user && (user as UserData).language;
  if (userLang && router.locale !== userLang) {
    window.location.href = `/${userLang}`;
    return null;
  }

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  );
};

export default { AuthContainer };
